import httpManager from "../axios/httpManager";

export function getModifiedHistoryList  (search, pageNum, pageSize) {
    let url = '/log/modified/list'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function updateModifiedHistory  (update) {
    let url = '/log/modified/update';
    return httpManager.post(url, update);
}

export function updateAllModifiedHistory  (update) {
    let url = '/log/modified/updateAllLog';
    return httpManager.post(url, update);
}